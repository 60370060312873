import { Helmet } from 'react-helmet'

import * as React from 'react'
import Layout from '../components/Layout'

import { Link, graphql } from 'gatsby'
import Img from 'gatsby-image'
import FAQs from '../components/FAQs'

import { Box, Flex } from 'grid-styled'
import CTA from '../components/CTA'
import GalleryContainer from '../components/GalleryContainer'
import GalleryImage from '../components/GalleryImage'
import {
  HomeHeader,
  FormContainer,
  AlternatingContent,
  AboutContact,
  VideoContainer,
  info
} from '.'
import Form from '../components/Form'
import { Text, GallerySection, Content } from '../components/styles'
import Button from '../components/Button'
import ContentCard from '../components/ContentCard'
import Hero from '../components/Hero'
import { Video } from '../components/procedures'

export default ({ data }) => (
  <Layout header="light">
    <Helmet>
      <title>
        Favoured Carport Builders in Brisbane and Sunshine Coast | Just Patios
      </title>
      <meta
        name="description"
        content="Just Patios are experienced carport designers and builders, servicing Brisbane and Sunshine Coast. We have over 20 years of experience with a team of licensed & professional builders. Call us today for a free carport design & quote."
      />
    </Helmet>

    <HomeHeader slant={false} height="75vh">
      <figure>
        <Img fluid={data.jpBackground.childImageSharp.fluid} />
      </figure>
      <div>
        <h2>Carport Builders Brisbane</h2>
        <Text size="large">
          Looking at building a carport for your home? Our team at Just Patios
          is here to help you every step of the way.
        </Text>
        <Link to="/contact-us/">
          <Button>Contact us today</Button>
        </Link>
      </div>
      <FormContainer>
        <div>
          <Form title="Request Free Quote" showMessage={true} />
        </div>
      </FormContainer>
    </HomeHeader>

    <Content style={{ paddingTop: 100 }}>
      <h1>
        Carport Builders in Brisbane <i>&amp;</i> The Sunshine Coast
      </h1>

      <h4>
        Need to protect your investments from our harsh environment? Cover your
        Car, Boat, Caravan, Motorbike or Jet Ski with a quality built,
        customised carport in Brisbane or the Sunshine Coast.
      </h4>

      <p>
        The highly-experienced carport builders at Just Patios create customised
        solutions to protect your valuable vehicles. It is terrible to think of
        your precious car being ravaged by harsh weather conditions and other
        corrosive elements. We’ll make sure that it never happens.
      </p>

      <p>
        Cars, boats, motorcycles and other vehicles are prone to rusting and
        other forms of damage. Extreme weather conditions, temperature changes
        and dirt and bacteria buildup can all contribute to the wear-and-tear on
        your vehicle. Like any owner, you want to keep your valuables in prime
        condition, the way they were when you first purchased them. Our custom
        carports are what you need to keep your vehicle in top shape.
      </p>

      <p>
        At Just Patios, we offer a wide range of carport designs for clients in
        the Brisbane and Sunshine Coast regions. From the popular flat/skillion
        options and raised/flyover type, to the gable carport, our builders can
        provide the ideal design for your vehicle and your home. With our
        solutions, there’s no need to rent extra space and storage units. Why
        would you still need them when there’s enough room at home?
      </p>

      <p>
        Functional and built to high a standard, our carports add value and
        aesthetic appeal to any home. Whether you’re in the Sunshine Coast or
        Brisbane areas, we’ll construct high-performance and stunning carports
        for you. View our range of designs below.
      </p>

      <Link to="/contact-us/">
        <Button inverted={true}>Contact us today!</Button>
      </Link>
    </Content>

    <AlternatingContent>
      <h2>
        Our <i>Carport</i> Range &amp; Designs
      </h2>
      <ContentCard
        image={data.training.childImageSharp}
        to="/carport-builders-brisbane/raised-flyover-carport"
        title="Raised Flyover Carport"
        text="This design is great to cover caravans, boats or motorhomes as they have extra height. We have numerous ways of attaching your new carport to your home so please feel free to call and let us help you design your new area.
        "
        buttons={[
          {
            to: '/carport-builders-brisbane/raised-flyover-carport',
            text: 'Learn more'
          }
        ]}
      />
      <ContentCard
        to="/carport-builders-brisbane/skillion-carport"
        image={data.patiosBrisbane.childImageSharp}
        title="Flat / Skillion Carport"
        text="These are by far the most popular carport designs as they are simple & cheap yet very effective. We can use our patio range of materials or for a cheaper version, zincalume sheets & a galvanised frame can be used. These systems are great for attaching off the side of the house or can be built as a free standing unit."
        buttons={[
          {
            to: '/carport-builders-brisbane/skillion-carport',
            text: 'Learn more'
          }
        ]}
      />
      <ContentCard
        image={data.offField.childImageSharp}
        title="Gable Carport"
        to="/carport-builders-brisbane/gable-carport"
        text="A Gable Carport can certainly add character and most importantly compliment your existing roof line, allowing it to seemlessly blend with your home. The Gable Carport range comes in 3 popular styles which are: traditional gable ends, hip ends, and dutch gable ends. These can all be customised to your home and needs. Styles are available in the full Colourbond and Colourbond equivalent range to marry in with your existing home colours.
        "
        buttons={[
          {
            to: '/carport-builders-brisbane/gable-carport',
            text: 'Learn more'
          }
        ]}
      />
    </AlternatingContent>

    <Hero bgImage={data.blurredHero.childImageSharp} height="550px">
      <h3>Want to find our more?</h3>
      <p>
        Call 1300 284 531 or complete the enquiry form to contact us today and
        find out how we can help.
      </p>
      <Flex>
        <Button color={'#fff'} small={true} outline={true}>
          Contact Us
        </Button>
      </Flex>
    </Hero>

    <GallerySection>
      <h2>Some of Our Recent Projects</h2>
      <p>
        For over 20 years, Just Patios has been providing robust carports in the
        Brisbane and Sunshine Coast regions. Using roofing systems from
        Australia’s leading roofing manufacturers, we create beautiful carports
        built to last. From gable carports to flat/skillion and flyover
        carports, we’ve done it all. Our licensed and professional builders do
        more than just deliver high-quality carports. We create structures that
        fit your lifestyle.
      </p>
      <p>
        We’ve hand-selected the best systems in Australia and use quality
        products backed with 15-year warranties. View photos from some of our
        most recent projects and discover for yourself what we can achieve for
        you.
      </p>
      <GalleryContainer>
        {data.gallery.edges.map(
          (image, i) =>
            image.node.childImageSharp && (
              <GalleryImage modal={image.node.childImageSharp.modal}>
                <Img fixed={image.node.childImageSharp.fixed} />
              </GalleryImage>
            )
        )}
      </GalleryContainer>
    </GallerySection>

    <FAQs
      flushTop={true}
      faqs={info}
      title={
        <>
          What sets <i>us</i> apart?
        </>
      }
      background={data.whyUs.childImageSharp.fluid.src}
    />

    <VideoContainer>
      <Video
        videoId="n9YvBXIci_E"
        opts={{
          height: '576',
          width: '1024',
          playerVars: {
            color: 'white',
            rel: 0
          }
        }}
      />
    </VideoContainer>

    <AboutContact style={{ margin: '0 auto', padding: 0 }} />
  </Layout>
)

export const query = graphql`
  query CarportsQuery {
    jpBackground: file(relativePath: { eq: "carport-builders-brisbane.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 3000, quality: 95) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    offField: file(relativePath: { eq: "gallery/carports/gable/cover.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    whyUs: file(relativePath: { eq: "why-us-jp.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 3000, quality: 95) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    patiosBrisbane: file(
      relativePath: { eq: "gallery/carports/skillion/cover.jpg" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    training: file(relativePath: { eq: "gallery/carports/flyover/cover.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }

    accessories: file(relativePath: { eq: "roofing-brisbane.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2000, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    blurredHero: file(relativePath: { eq: "blurred-hero.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    gallery: allFile(
      filter: { relativeDirectory: { eq: "gallery/carports" } }
    ) {
      edges {
        node {
          childImageSharp {
            fixed(width: 400, height: 400, toFormat: JPG) {
              ...GatsbyImageSharpFixed
            }
            modal: fluid(maxWidth: 1200, quality: 98, toFormat: JPG) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
